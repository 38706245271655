var render = function render(){var _vm=this,_c=_vm._self._c;return _c('vue-slider',{class:[
    {'vue-slider-margin-for-tooltip': _vm.tooltip && _vm.isArray && !_vm.smartTooltipPosition},
    _vm.size
  ],attrs:{"value":_vm.value,"min":_vm.min,"max":_vm.max,"tooltip":(_vm.tooltip) ? 'always' : 'none',"interval":_vm.interval,"tooltip-placement":_vm.tooltipPlacement,"lazy":_vm.lazy,"dot-size":_vm.dotSize,"contained":_vm.contained,"enable-cross":false},on:{"change":function($event){return _vm.$emit('input', $event)},"dragging":function($event){_vm.internalValue = $event}},scopedSlots:_vm._u([(_vm.tooltip)?{key:"tooltip",fn:function({value, index}){return [_c('div',{class:[
        'vue-slider-dot-tooltip-inner',
        `vue-slider-dot-tooltip-inner-${_vm.tooltipPlacement[index]}`,
        _vm.size
        ],on:{"mousedown":function($event){$event.stopPropagation();},"click":function($event){$event.stopPropagation();return _vm.startEdition(index)}}},[(_vm.indexEdited !== index)?[_vm._t("default",function(){return [_vm._v(" "+_vm._s(Math.round(value * 1000)/1000)+" ")]},{"value":value})]:_c('b-input',{ref:"inputSlider",attrs:{"type":"text","size":_vm.size},on:{"hook:mounted":function($event){return _vm.focus()},"blur":function($event){return _vm.stopEdition(index)}},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.stopEdition(index)}},model:{value:(_vm.editedValue),callback:function ($$v) {_vm.editedValue=$$v},expression:"editedValue"}})],2)]}}:null],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }